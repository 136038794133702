import React from "react";
import { Link } from "react-router-dom";

const AppTemplateNav = (props) => {
  const { appVersion } = props;
  let links = (
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <Link
          className="nav-link btn btn-link"
          to={`/dashboard${
            typeof appVersion === "undefined" || appVersion === ""
              ? ""
              : `?AV=${appVersion}`
          }`}
        >
          <i className="far fa-money-bill-alt" />
        </Link>
      </li>
      <li className="nav-item">
        <button className="btn btn-link nav-link" onClick={props.logout}>
          <i className="fas fa-power-off" />
        </button>
      </li>
      <li className="nav-item">
        <a
          className="nav-link btn btn-link"
          href={`/cp/${
            typeof appVersion === "undefined" || appVersion === ""
              ? ""
              : `?AV=${appVersion}`
          }`}
          rel="noreferrer"
        >
          <i className="fas fa-users-cog" />
        </a>
      </li>
    </ul>
  );

  return <>{links}</>;
};

export default AppTemplateNav;
