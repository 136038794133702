import React, { useContext, useEffect, useState } from "react";
import { UnitPurchaseContext } from "../../UnitModal";
import { UnitsContext } from "../../Dashboard";

const UnitNav = () => {
  const UnitsData = useContext(UnitsContext);
  const UnitPurchaseData = useContext(UnitPurchaseContext);

  const [paymentMethod, setPaymentMethod] = useState(
    UnitPurchaseData.paymentMethod ? UnitPurchaseData.paymentMethod : null
  );
  const [hotelName, setHotelName] = useState(
    UnitPurchaseData.hotelName ? UnitPurchaseData.hotelName : null
  );
  const [hotelRoomNumber, setHotelRoomNumber] = useState(
    UnitPurchaseData.hotelRoomNumber ? UnitPurchaseData.hotelRoomNumber : null
  );
  const [hotelLastName, setHotelLastName] = useState(
    UnitPurchaseData.hotelLastName ? UnitPurchaseData.hotelLastName : null
  );
  const [guestName, setGuestName] = useState(
    UnitPurchaseData.guestName ? UnitPurchaseData.guestName : null
  );
  const [selectedEquipment, setSelectedEquipment] = useState(
    UnitPurchaseData.selectedEquipment
      ? UnitPurchaseData.selectedEquipment
      : null
  );
  const [orderTotal, setOrderTotal] = useState(
    UnitPurchaseData.orderTotal ? UnitPurchaseData.orderTotal : null
  );

  const cancelPaymentMethod = () => {
    UnitPurchaseData.logAction("Cancel Payment Method");
    UnitPurchaseData.setPaymentMethod("");
    UnitPurchaseData.setHotelRoomNumber("");
    UnitPurchaseData.setHotelLastName("");
    UnitPurchaseData.setHotelName("");
    UnitPurchaseData.setUnitStep("Select Payment Method");
  };

  const cancelSelectedUnit = () => {
    UnitPurchaseData.logAction("Cancel selected unit");
    UnitPurchaseData.setSelectedEquipment("");
    UnitPurchaseData.setSelectedAddOns("");
    UnitPurchaseData.setOrderTotal(0);
    UnitPurchaseData.setAddOnsTotal(0);

    // if (UnitPurchaseData.hotelName) {
    //   UnitPurchaseData.setHotelName("");
    //   UnitPurchaseData.setHotelRoomNumber("");
    //   UnitPurchaseData.setHotelLastName("");
    //   UnitPurchaseData.setUnitStep("Select Hotel");
    // } else {
    //   UnitPurchaseData.setUnitStep("Select Equipment");
    // }
    UnitPurchaseData.setUnitStep("Select Equipment");
  };

  const cancelGuestName = () => {
    UnitPurchaseData.logAction("Cancel Guest Name");
    UnitPurchaseData.setGuestName("");
    UnitPurchaseData.setUnitStep("Enter Customer Name");
  };

  const hotelNameConversion = (hotelName) => {
    switch (hotelName) {
      case "W":
        return "Wyndham";
      case "H":
        return "Hyatt";
      case "O Sands":
        return "Opal Sands";
      case "O Sol":
        return "Opal Sol";
      case "Hiatus CB":
        return "Hiatus CB";
      default:
        return hotelName;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {paymentMethod && (
          <button
            onClick={() => cancelPaymentMethod()}
            className="btn btn-outline-danger btn-sm me-2"
          >
            {paymentMethod} <i className="fas fa-times" />
          </button>
        )}

        {hotelName && (
          <button
            onClick={() => cancelPaymentMethod()}
            className="btn btn-outline-danger btn-sm me-2"
          >
            {hotelNameConversion(hotelName)} <i className="fas fa-times" />
          </button>
        )}

        {hotelName && hotelRoomNumber !== 0 && hotelLastName && (
          <button
            onClick={() => cancelPaymentMethod()}
            className="btn btn-outline-danger btn-sm me-2"
          >
            {hotelLastName} / #{hotelRoomNumber} <i className="fas fa-times" />{" "}
          </button>
        )}

        {selectedEquipment && (
          <button
            onClick={() => cancelSelectedUnit()}
            className="btn btn-outline-danger btn-sm me-2"
          >
            {selectedEquipment}
            {!UnitsData.hasCartUnits && <>&nbsp; ${orderTotal}</>}
            &nbsp;
            <i className="fas fa-times" />
          </button>
        )}
        {guestName && (
          <button
            onClick={() => cancelGuestName()}
            className="btn btn-outline-danger btn-sm me-2"
          >
            {guestName}
            &nbsp;
            <i className="fas fa-times" />
          </button>
        )}
      </div>
    </div>
  );
};

export default UnitNav;
