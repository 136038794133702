import React, { useContext } from "react";
import { UnitPurchaseContext } from "../../UnitModal";

const SelectHotel = () => {
  const UnitsData = useContext(UnitPurchaseContext);

  const setHotelName = (hotelName) => {
    if (hotelName === "O Sol") {
      UnitsData.setHotelName("Opal Sol");
    } else if (hotelName === "O Sands") {
      UnitsData.setHotelName("Opal Sands");
    } else if (hotelName === "W") {
      UnitsData.setHotelName("Wyndham");
    } else if (hotelName === "H") {
      UnitsData.setHotelName("Hyatt");
    } else {
      UnitsData.setHotelName(hotelName);
    }
    if (hotelName === "W") {
      UnitsData.updateUnitStep("Select Hotel Customer Name Wyndham");
    } else if (hotelName === "H") {
      UnitsData.updateUnitStep("Select Hotel Customer Name Hyatt");
    } else {
      UnitsData.updateUnitStep("Select Hotel Customer Name");
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12 text-center my-4">
        <div className="d-inline-flex gap-4">
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => setHotelName("W")}
          >
            <h1>
              <i className="fas fa-hotel" />
            </h1>
            WYNDHAM
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => setHotelName("H")}
          >
            <h1>
              <i className="fas fa-hotel" />
            </h1>
            HYATT
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => setHotelName("O Sands")}
          >
            <h1>
              <i className="fas fa-hotel" />
            </h1>
            OPAL SANDS
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => setHotelName("O Sol")}
          >
            <h1>
              <i className="fas fa-hotel" />
            </h1>
            OPAL SOL
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => setHotelName("Hiatus CB")}
          >
            <h1>
              <i className="fas fa-hotel" />
            </h1>
            HIATUS CB
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectHotel;
