import React, { useContext, useEffect, useState } from "react";
import { UnitPurchaseContext } from "../../UnitModal";
import Items from "./SelectEquipment/Items";
import HotelItems from "./SelectEquipment/HotelItems";
import { UnitsContext } from "../../Dashboard";

const SelectEquipment = (props) => {
  let currentDateTime = new Date();

  let minutes = currentDateTime.getMinutes().toString();
  minutes = minutes.padStart(2, "0");
  let hours = currentDateTime.getHours().toString();
  hours = hours.padStart(2, "0");
  let currentTime = parseInt(hours + minutes);

  const UnitsData = useContext(UnitsContext);

  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const unitType = UnitPurchaseData.unitData.umbrellaType;

  // if hotel room charge
  const hotelName = UnitPurchaseData.hotelName;
  const hotelRoomNumber = UnitPurchaseData.hotelRoomNumber;

  // if not hotel room charge
  const equipmentData = UnitPurchaseData.unitData.equipmentData;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(new Set());

  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [selectedAddOns, setSelectedAddOns] = useState("");

  const [addOnsTotal, setAddonTotal] = useState(0.0);
  const [subTotal, setSubTotal] = useState(0.0);
  const [orderTotal, setOrderTotal] = useState(0.0);
  const [retailTotal, setRetailTotal] = useState(0.0);

  const [hotelRoomEnding, setHotelRoomEnding] = useState(0);

  let wyndhamExtraCharge = UnitPurchaseData.wyndhamExtraCharge;
  let hyattExtraCharge = UnitPurchaseData.hyattExtraCharge;

  const toggleCheckboxChange = (label) => {
    let tmpAddons = ",";
    let tmpAddonTotal = 0;
    let tmpSelectedCheckboxes = selectedCheckboxes;
    if (tmpSelectedCheckboxes.has(label)) {
      tmpSelectedCheckboxes.delete(label);
    } else {
      tmpSelectedCheckboxes.add(label);
    }
    for (const checkbox of tmpSelectedCheckboxes) {
      tmpAddons += checkbox + ",";
      if (hotelName === "Wyndham") {
        if (hotelRoomEnding >= 70 || wyndhamExtraCharge === 1) {
          for (const unit of equipmentData.addons) {
            if (
              parseInt(unit.startTime) <= currentTime &&
              parseInt(unit.endTime) >= currentTime &&
              unit.unitType === checkbox
            ) {
              tmpAddonTotal += parseFloat(unit.price);
            }
          }
        }
        if (hotelRoomEnding < 70 && wyndhamExtraCharge !== 1) {
          for (const unit of equipmentData.hotelAddons) {
            if (
              parseInt(unit.startTime) <= currentTime &&
              parseInt(unit.endTime) >= currentTime &&
              unit.hotels === hotelName &&
              unit.unitType === checkbox
            ) {
              tmpAddonTotal += parseFloat(unit.price);
            }
          }
        }
      } else if (hotelName === "Hyatt") {
        for (const unit of equipmentData.hotelAddons) {
          if (
            parseInt(unit.startTime) <= currentTime &&
            parseInt(unit.endTime) >= currentTime &&
            unit.hotels === hotelName &&
            unit.unitType === checkbox
          ) {
            tmpAddonTotal += parseFloat(unit.price);
          }
        }
      } else {
        for (const unit of equipmentData.addons) {
          if (
            parseInt(unit.startTime) <= currentTime &&
            parseInt(unit.endTime) >= currentTime &&
            unit.unitType === checkbox
          ) {
            tmpAddonTotal += parseFloat(unit.price);
          }
        }
      }
    }
    setSelectedCheckboxes(tmpSelectedCheckboxes);
    setSelectedAddOns(tmpAddons);
    setAddonTotal(tmpAddonTotal);
    setOrderTotal(parseFloat(tmpAddonTotal) + parseFloat(subTotal));
  };

  const selectItem = (value, price) => {
    setSubTotal(parseFloat(price));
    setSelectedEquipment(value);
    getRetailPrice(value);
  };

  const getRetailPrice = (selectedItem) => {
    let tmpEquipment;
    let tmpUnit;
    if (
      (hotelName === "Wyndham" &&
        wyndhamExtraCharge !== 1 &&
        hotelRoomEnding < 70) ||
      hotelName === "Hyatt"
    ) {
      tmpEquipment = equipmentData?.hotelEquipment.filter(
        (item) => item.hotels === hotelName
      );
      tmpUnit = tmpEquipment.filter((item) => {
        return (
          (item.equipmentType === selectedItem ||
            item.unitType === selectedItem) &&
          currentTime >= item.startTime &&
          currentTime <= item.endTime
        );
      })[0];
    } else {
      tmpUnit = equipmentData?.equipment.filter(
        (item) =>
          (item.unitType === selectedItem ||
            item.equipmentType === selectedItem) &&
          currentTime >= item.startTime &&
          currentTime <= item.endTime
      )[0];
    }
    setRetailTotal(parseFloat(tmpUnit?.retailRate));
    setOrderTotal(parseFloat(tmpUnit?.price) + parseFloat(addOnsTotal));
  };

  const saveEquipment = () => {
    // equipmentSelected
    UnitPurchaseData.setSelectedEquipment(selectedEquipment);
    UnitPurchaseData.setSelectedAddOns(selectedAddOns);

    UnitPurchaseData.setAddOnsTotal(addOnsTotal);
    UnitPurchaseData.setSubTotal(subTotal);
    UnitPurchaseData.setRetailTotal(retailTotal);
    UnitPurchaseData.setOrderTotal(orderTotal);

    UnitPurchaseData.logAction(
      "Selected Equipment - " + selectedEquipment + ", " + selectedAddOns
    );

    if (UnitsData.hasCartUnits === false) {
      if (UnitPurchaseData.paymentMethod !== "Room Charge") {
        UnitPurchaseData.updateUnitStep("Enter Customer Name");
      } else {
        UnitPurchaseData.updateUnitStep("Review Order");
      }
    } else {
      UnitPurchaseData.updateUnitStep("Review Order");
    }
  };

  useEffect(() => {
    let tmpUnit;
    let tmpEquipment;
    let tmpUnitSelected;
    let tmpUnitPrice = 0;
    let roomNumberLength;
    let tmpHotelRoomEnding;

    if (
      hotelRoomNumber !== "" &&
      hotelRoomNumber !== null &&
      typeof hotelRoomNumber !== "undefined"
    ) {
      roomNumberLength = hotelRoomNumber.toString().length;
      tmpHotelRoomEnding = hotelRoomNumber
        .toString()
        .substring(roomNumberLength - 2, roomNumberLength);
      setHotelRoomEnding(tmpHotelRoomEnding);
    }

    if (unitType) {
      if (
        (hotelName === "Wyndham" &&
          wyndhamExtraCharge !== 1 &&
          tmpHotelRoomEnding < 70) ||
        (hotelName === "Hyatt" && hyattExtraCharge !== 1)
      ) {
        tmpEquipment = equipmentData.hotelEquipment.filter(
          (item) => item.hotels === hotelName
        );
        tmpUnit = tmpEquipment.filter(
          (equipment) =>
            (equipment.equipmentType === unitType ||
              equipment.unitType === unitType) &&
            parseInt(equipment.startTime) <= currentTime &&
            parseInt(equipment.endTime) >= currentTime
        )[0];
      } else {
        tmpUnit = equipmentData.equipment.filter(
          (equipment) =>
            (equipment.equipmentType === unitType ||
              equipment.unitType === unitType) &&
            parseInt(equipment.startTime) <= currentTime &&
            parseInt(equipment.endTime) >= currentTime
        )[0];
      }

      tmpUnitSelected = tmpUnit?.unitType;
      tmpUnitPrice = tmpUnit?.price;
    }

    setSelectedEquipment(tmpUnitSelected);
    setSubTotal(tmpUnitPrice);
    setOrderTotal(tmpUnitPrice);
    setRetailTotal(tmpUnit?.retailRate);
  }, []);

  return (
    <div className="container mb-4">
      <div className="row">
        <div className="col-sm-12">
          <h3 className="mt-3">Equipment</h3>
          Current Time: {hours}:{minutes}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table className="table table-striped">
            <thead className="table-dark">
              <tr>
                <th scope="col">Addon Total</th>
                <th scope="col">Sub Total</th>
                <th scope="col">Retail Total</th>
                <th scope="col">Order Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${parseFloat(addOnsTotal)}</td>
                <td>${parseFloat(subTotal)}</td>
                <td>${parseFloat(retailTotal) + parseFloat(addOnsTotal)}</td>
                <td>${parseFloat(orderTotal)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {hotelName === "Wyndham" &&
        ((hotelRoomEnding >= 70 && wyndhamExtraCharge !== 1) ||
          wyndhamExtraCharge === 1) && (
          <>
            WVO Guest
            <br />
            <Items
              currentTime={currentTime}
              equipmentData={equipmentData}
              selectedEquipment={selectedEquipment}
              selectedAddOns={selectedAddOns}
              toggleCheckboxChange={toggleCheckboxChange}
              onChangeRadio={selectItem}
            />
          </>
        )}
      {hotelName === "Wyndham" &&
        wyndhamExtraCharge !== 1 &&
        hotelRoomEnding < 70 && (
          <HotelItems
            currentTime={currentTime}
            hotelName={hotelName}
            equipmentData={equipmentData}
            selectedEquipment={selectedEquipment}
            selectedAddOns={selectedAddOns}
            toggleCheckboxChange={toggleCheckboxChange}
            onChangeRadio={selectItem}
          />
        )}
      {hotelName === "Hyatt" && hyattExtraCharge !== 1 && (
        <HotelItems
          currentTime={currentTime}
          hotelName={hotelName}
          equipmentData={equipmentData}
          selectedEquipment={selectedEquipment}
          selectedAddOns={selectedAddOns}
          toggleCheckboxChange={toggleCheckboxChange}
          onChangeRadio={selectItem}
        />
      )}
      {((hotelName !== "Wyndham" && hotelName !== "Hyatt") ||
        (hotelName === "Hyatt" && hyattExtraCharge === 1)) && (
        <Items
          currentTime={currentTime}
          equipmentData={equipmentData}
          selectedEquipment={selectedEquipment}
          selectedAddOns={selectedAddOns}
          toggleCheckboxChange={toggleCheckboxChange}
          onChangeRadio={selectItem}
        />
      )}
      {selectedEquipment && (
        <div className="row">
          <div className="col-sm-12 col-md-6 offset-md-3 text-center">
            <button
              className="btn btn-block btn-primary btn-lg mt-4"
              onClick={() => saveEquipment()}
            >
              CONTINUE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectEquipment;
