const Checkout = ({ goToCart }) => {
  return (
    <div className="container bg-white vh-100">
      <div className="row">
        <div className="col-12 mt-4">
          <h1 className="text-center">Enter Payment</h1>
        </div>
        <div className="col-12">
          <hr />
        </div>

        <div className="col-12">Location:</div>
        <div className="col-12">Name:</div>
        <div className="col-12">Phone:</div>

        <div className="col-12">
          <form>
            <div className="mb-3">
              <label htmlFor="cardNumber" className="form-label">
                Card Number:
              </label>
              <input type="text" className="form-control" id="cardNumber" />
            </div>
            <div className="mb-3">
              <label htmlFor="expiration" className="form-label">
                Expiration Date:
              </label>
              <input type="text" className="form-control" id="expiration" />
            </div>
            <div className="mb-3">
              <label htmlFor="cvv" className="form-label">
                CVV:
              </label>
              <input type="text" className="form-control" id="cvv" />
            </div>
            <div className="mb-3">
              <label htmlFor="zip" className="form-label">
                Zip Code:
              </label>
              <input type="text" className="form-control" id="zip" />
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">
                Submit Payment
              </button>
            </div>
          </form>
        </div>
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-secondary mt-4"
            onClick={goToCart}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
