import React, { useEffect, useState } from "react";
import { tokenConfig } from "client/actions/apiActions";
import logo from "../../../assets/bh-logo.png";
import Loading from "../../layout/Loading";
import axios from "axios";
import "../../../assets/Menu.css";
import { ApiURL } from "../../../config";
import Cart from "./Cart";
import Checkout from "./Checkout";

const FoodMenu = () => {
  const [menu, setMenu] = useState({});
  const [loading, setLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  const loadMenu = async () => {
    const res = await axios.get(`${ApiURL}/getMenu`, tokenConfig());
    console.log("res", res.data.menu);
    setMenu(res.data.menu);
    setLoading(false);
  };

  useEffect(() => {
    loadMenu();
  }, []);

  const updateCart = () => {
    setShowCart(true);
  };

  const showCheckoutFunc = () => {
    setShowCart(false);
    setShowCheckout(true);
  };

  const goToCart = () => {
    setShowCheckout(false);
    setShowCart(true);
  };

  if (loading) {
    return <Loading />;
  }

  if (showCheckout) {
    return <Checkout goToCart={goToCart} />;
  }

  if (showCart) {
    return <Cart setShowCart={setShowCart} showCheckout={showCheckoutFunc} />;
  }

  const cart = localStorage.getItem("foodCart");

  return (
    <div className="container my-4 bg-white rounded">
      <div className="row">
        <div className="col-12 text-right">
          <button className="btn btn-warning">Cart</button>
          {cart && (
            <button
              className="btn btn-warning"
              onClick={() => setShowCart(true)}
            >
              View Cart
            </button>
          )}
        </div>

        <div className="col-12 text-center">
          {logo && (
            <img
              src={logo}
              className="img-fluid"
              alt="Menu"
              style={{ maxWidth: "300px" }}
            />
          )}
          <h1 className={"text-center"}>{menu.name}</h1>
          {menu?.image && (
            <>
              <img src={`${menu.image}`} className="img-fluid" alt={"Menu"} />
              <br />
            </>
          )}
        </div>
        <div className="col-12 mt-4">
          <div dangerouslySetInnerHTML={{ __html: menu?.description }} />
        </div>

        {menu?.categories?.map((category, index) => {
          return (
            <>
              <div className="col-12">
                <h2 className="h2 mt-4 border-bottom text-center">
                  {category.name}
                </h2>
                <div className="text-center">{category.description}</div>
              </div>
              <MenuCategories
                key={index}
                category={category}
                updateCart={updateCart}
              />
            </>
          );
        })}
        <div className="col-12 mt-4 mb-4">
          <hr />
        </div>
        <div className="col-12 mt-4 mb-4">{menu?.disclaimer}</div>
      </div>
    </div>
  );
};
export default FoodMenu;

const MenuCategories = ({ category, updateCart }) => {
  return (
    <>
      <div className="col-12">
        {category.subTitle !== "" &&
          typeof category.subTitle !== "undefined" && (
            <h3 className="h3 mt-4 border-bottom">{category.subTitle}</h3>
          )}
      </div>
      <div className="MenuColumn mt-4">
        {category?.items?.map((item, index) => {
          return <MenuItem key={index} item={item} updateCart={updateCart} />;
        })}
      </div>
    </>
  );
};

const MenuItem = ({ item, updateCart }) => {
  if (typeof item.price2Description === "undefined") {
    item.price2Description = "";
  }
  if (typeof item.price3Description === "undefined") {
    item.price3Description = "";
  }
  if (typeof item.price4Description === "undefined") {
    item.price4Description = "";
  }
  if (typeof item.price5Description === "undefined") {
    item.price5Description = "";
  }
  let menuItemContent = "";
  if (item.image !== "") {
    menuItemContent +=
      '<div class="col-12 text-center"><img src="' +
      item.image +
      '" class="img-fluid" alt="" /></div><br />';
  }
  menuItemContent += '<b class="text-capitalize">' + item.name + "</b>";
  if (item.favorite === 1 || item.favorite === "1") {
    menuItemContent += ' - <span class="badge text-bg-danger">FAV</span>';
  }
  if (item.description !== "") {
    menuItemContent += "<br/>";
  }
  menuItemContent += item.description;

  const addToOrder = () => {
    const order = localStorage.getItem("foodCart");

    let orderData = {};
    if (order) {
      orderData = JSON.parse(order);
    }

    let items = [];
    if (orderData.items) {
      items = orderData.items;
    }

    console.log("item", item);

    let qty = 1;

    // look up if item already exists in cart
    for (let i = 0; i < items.length; i++) {
      if (items[i].itemId === item.id) {
        qty = items[i].qty + 1;
        items.splice(i, 1);
        break;
      }
    }

    items.push({
      qty: qty,
      itemId: item.id,
      name: item.name,
      image: item.image,
      price: item.price,
    });
    orderData["items"] = items;

    orderData["subTotal"] = 0;
    for (let i = 0; i < items.length; i++) {
      orderData["subTotal"] += parseFloat(items[i].price);
    }

    orderData["tax"] = 0;
    orderData["total"] = 0;
    localStorage.setItem("foodCart", JSON.stringify(orderData));
    updateCart();
  };

  return (
    <div className={`mt-2 col-12 mb-4 MenuItem`}>
      <div dangerouslySetInnerHTML={{ __html: menuItemContent }} />
      {item.priceDescription && (
        <div className="container">
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-warning mt-1"
                type="button"
                onClick={() => addToOrder(item.price, item.priceDescription)}
              >
                Add to Bag - ${item.price}
              </button>
            </div>
            <div className="col-6">{item.priceDescription}</div>
          </div>
        </div>
      )}
      {item.price2Description && (
        <div className="container">
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-warning mt-1"
                type="button"
                onClick={() => addToOrder(item.price2, item.price2Description)}
              >
                Add to Bag - ${item.price2}
              </button>
            </div>
            <div className="col-6">{item.price2Description}</div>
          </div>
        </div>
      )}
      {item.price3Description && (
        <div className="container">
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-warning mt-1"
                type="button"
                onClick={() => addToOrder(item.price3, item.price3Description)}
              >
                Add to Bag - ${item.price3}
              </button>
            </div>
            <div className="col-6">{item.price3Description}</div>
          </div>
        </div>
      )}
      {item.price4Description && (
        <div className="container">
          <div className="row">
            <div className="col-6 ">
              <button
                className="btn btn-warning mt-1"
                type="button"
                onClick={() => addToOrder(item.price4, item.price4Description)}
              >
                Add to Bag - ${item.price4}
              </button>
            </div>
            <div className="col-6">{item.price4Description}</div>
          </div>
        </div>
      )}
      {item.price5Description && (
        <div className="container">
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-warning mt-1"
                type="button"
                onClick={() => addToOrder(item.price5, item.price5Description)}
              >
                Add to order - ${item.price5}
              </button>
            </div>
            <div className="col-6">{item.price5Description}</div>
          </div>
        </div>
      )}
      {!item.price2Description &&
        !item.price3Description &&
        !item.price4Description &&
        !item.price5Description && (
          <div className="d-grid gap-2">
            <button
              className="btn btn-warning mt-4"
              onClick={addToOrder}
              type="button"
            >
              Add to Bag - ${item.price}
            </button>
          </div>
        )}
      <hr />
    </div>
  );
};
