import { useState } from "react";

const Cart = ({ setShowCart, showCheckout }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("foodCart")),
  );

  const removeItem = (index) => {
    const order = JSON.parse(localStorage.getItem("foodCart"));
    order.items.splice(index, 1);
    localStorage.setItem("foodCart", JSON.stringify(order));
    setCartItems(order);
    if (order.items.length === 0) {
      setShowCart(false);
    }
  };

  const subTotal = cartItems?.items?.reduce((acc, item) => {
    return acc + parseFloat(item.price) * item.qty;
  }, 0);

  return (
    <div className="container bg-white vh-100">
      <div className="row">
        <div className="col-12 mt-4">
          <h1 className="text-center">Food Selected</h1>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12">
          {cartItems?.items?.map((item, index) => {
            return (
              <div className="container" key={index}>
                <div className="row">
                  <div className="col-3">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-auto">
                    {item.name}
                    <br />${item.price} x {item.qty}
                  </div>
                  <div className="col text-end">
                    <button
                      className="btn btn-danger mt-1"
                      onClick={() => removeItem(index)}
                    >
                      X
                    </button>
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-12 text-center">Sub Total: ${subTotal}</div>
        <div className="col-12">
          <div className="d-grid gap-2">
            <button className="btn btn-success mt-4" onClick={showCheckout}>
              Checkout
            </button>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="d-grid gap-2">
            <button
              className="btn btn-warning mt-4"
              onClick={() => setShowCart(false)}
            >
              Add More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;
